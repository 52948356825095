/*
 * Modules
 */

import "./modules/slider.js";
import "./modules/mobile-nav.js";
import "./modules/init-aos.js";
import "./modules/stickysidebar.js";
import "./modules/item-matchHeight.js";
import "./modules/jquery-toc.min.js";
import "./modules/splide.js";

import "./modules/mixitup.js";
