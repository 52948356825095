import { $win } from "../utils/globals";
import Swiper from "swiper/js/swiper.min";

$win.on("load", () => {
	$(".js-slider").each((i, elem) => {
		const sliderMain = $(elem).find(".swiper-container")[0];

		new Swiper(sliderMain, {
			loop: true,
            autoplay: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
			},
		});
	});
});
