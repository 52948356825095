jQuery(document).ready(function () {

    var container = document.querySelector('[data-ref="mixitup-container"]');
    var inputSearch = document.querySelector('[data-ref="input-search"]');
    var keyupTimeout;

    if (container) {

        var mixer = mixitup(container, {
            animation: {
                duration: 350
            },
            callbacks: {
                onMixClick: function() {
                    // Reset the search if a filter is clicked
                    if (this.matches('[data-filter]')) {
                        inputSearch.value = '';
                    }
                },
                onMixEnd: function (state, futureState) {
                    var elms = state.show;

                    elms.forEach(function (el, idx) {
                        el.classList.remove('aos-init');
                    })
                }

            }
        });

        // Set up a handler to listen for "keyup" events from the search input

        inputSearch.addEventListener('keyup', function() {
            var searchValue;

            if (inputSearch.value.length < 3) {
                // If the input value is less than 3 characters, don't send

                searchValue = '';
            } else {
                searchValue = inputSearch.value.toLowerCase().trim();
            }

            // Very basic throttling to prevent mixer thrashing. Only search
            // once 350ms has passed since the last keyup event

            clearTimeout(keyupTimeout);

            keyupTimeout = setTimeout(function() {
                filterByString(searchValue);
            }, 350);
        });
    }

    /**
     * Filters the mixer using a provided search string, which is matched against
     * the contents of each target's "class" attribute. Any custom data-attribute(s)
     * could also be used.
     *
     * @param  {string} searchValue
     * @return {void}
     */

    function filterByString(searchValue) {
        console.log(searchValue);
        if (searchValue) {
            // Use an attribute wildcard selector to check for matches

            mixer.filter('[data-name*="' + searchValue + '" i]');
        } else {
            // If no searchValue, treat as filter('all')

            mixer.filter('all');
        }
    }

})
